import { useSelector, useDispatch } from 'react-redux';
import Footer from '../Footer/Footer';
import classes from './Layout.module.css';
import Navigation from '../Navigation/Navigation';
import Header from '../Header/Header';

const Layout = (props) => {
  const user = useSelector(state => state.user);

  return <>
    {user.username !== '' && <Header />}
    {user && user.username && <div>
      <div className={classes['left-panel']}><Navigation /></div>
      <div className={classes['right-panel']}>
        <main className={classes.main}>
          {props.children}
        </main>
        {user.username !== '' && <Footer />}
      </div>
    </div>}

    {!user.username && (
        <div className="bg-login">
          <div className="bl_login">
            <main className={classes.main1}>{props.children}</main>
          </div>
        </div>
      )}
  </>;
};

export default Layout;